:root {
  --lightblue: rgb(230, 242, 247);
  --lightbluegray: rgb(239, 245, 247);
  --orange: #ff8a00;
  --blackish: rgb(35, 34, 34);
}

html,
body,
#root,
.vh100 {
  background: var(--blackish);
  overflow-y: scroll;
}

html {
  background-color: black;
  border: 5px solid black;
}

h1 {
  font-size: 48pt !important;
  text-shadow: 2px 2px 4px white;
  font-family: "Arial";
}

.title-color {
  color: black;
  font-weight: normal;
}

.thick-shadow {
  text-shadow: 1px 1px 4px black;
}

.small-text {
  font-size: 10pt;
}

.swap-option {
  max-width: 300px;
  margin-top: 10px;
  margin: auto;
}

.small-text {
  font-size: 10pt;
}

.main-container {
  border-top: 1px solid grey;
}

#node-section,
#contact-section,
#swap-section {
  max-width: 800px;
  margin: auto;
  border: 2px solid black;
  border-radius: 10px;
  color: black;
  box-shadow: #ff8a00 0px 0px 50px;
  margin-bottom: 100px;
}

#chainval {
  text-align: left;
}

#check-image {
  max-width: 100px;
}

.node-text {
  width: 75%;
  margin: auto;
}

.centered {
  margin: auto;
}

.center-contents {
  text-align: center;
}

.hover:hover {
  cursor: pointer;
}


.modal-body {
  overflow-wrap: break-word;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 38pt !important;
  }
}

@media screen and (max-width: 545px) {
  h1 {
    font-size: 22pt !important;
  }

  .subtitle {
    font-size: 10pt !important;
  }

  .in-text {
    font-size: 10pt !important;
  }
}

@media screen and (max-width: 345px) {
  h1 {
    font-size: 20pt !important;
  }

  .subtitle {
    font-size: 9pt !important;
  }

  .in-text,
  .small-text {
    font-size: 8pt !important;
  }
}

@media screen and (max-width: 315px) {
  h1 {
    font-size: 15pt !important;
  }

  .subtitle {
    font-size: 9pt !important;
  }

  .in-text,
  .small-text {
    font-size: 6pt !important;
  }
}